.form {
    &__group:not(:last-child) {
        margin-bottom: 2rem;

        
    @include respond (tab-port) {
      
        margin-bottom: 1.5rem;
       
    }

    }
    &__input{
    font-size: inherit;
    margin-top: 0;
    padding:1.5rem 2rem;
    border-radius: 2px;
    background-color: rgba($color-pink-light-0, .5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 70%;
    display: block;
    transition: all .3s;

    @include respond (tab-port) {
      
        width: 60%;
        padding:0.7rem 0.5rem;
       
    }

    &:focus {
        outline: none;
        box-shadow: 1rem 2rem rgba($color-white , .2);
        border-bottom: 3px solid $color-pink-light;
    }

    &:focus:invalid {
        border-bottom: 3px solid $color-alert;
    }

    &::-webkit-input-placeholder {
        color:$color-grey-dark-3;
    }

    &__icon {
        color: $color-grey-light-1;
    }
    
}

    &__label{
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block;
    transition: all .3s;
    }

    &__input:placeholder-shown + &__label {
        opacity: 0;
        visibility: hidden;
       transform: translateY(-4rem);
           }

}
