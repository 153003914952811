
   
    .btn{

        &,
        &:link,
        &:visited  {
            text-transform: uppercase;
            text-decoration: none;
            padding: 1.5rem 4rem;
            display: inline-block;
            border-radius: 10rem;
            transition: all .2s;
            position: relative;
            font-size: 1.6rem;
        }
      
    
        &:hover {
            transform: translateY(-0.3rem);
            box-shadow: 0 1rem 8rem rgba($color-black, .2);
    
            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
        &:active {
            transform: translateY(-0.1rem);
            box-shadow: 0 0.5rem 4rem rgba($color-black, .2);
        }
    
       &--white {
            background-color: $color-white;
            color: $color-grey-dark;
            margin-top: 20rem;
    
        &::after {
            background-color: $color-white;
        }

        }


        &--pink {
            background-color: $color-pink-light;
            color: $color-grey-dark;
    
        &::after {
            background-color: $color-pink-light;
        }

        }

        &::after {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            border-radius: 10rem;
            position: absolute;
            top:0;
            left: 0;
            z-index: -1;
            transition: all, .4s;
        }
     
        &-animated {
            animation: moveInBotton .5s ease-out .75s;
            animation-fill-mode: backwards;
        }
    
       }
    