@keyframes moveInLeft {
    0%{
     opacity: 0;
     transform: translateX(-100px);
    }
    80%{
       transform: translateX(10px);
    }
    100%{
     opacity: 1;
     transform: translate(0);
    }
   }
   
   
   @keyframes moveInRight {
       0%{
        opacity: 0;
        transform: translateX(100px);
       }
       80%{
          transform: translateX(-10px);
       }
       100%{
        opacity: 1;
        transform: translate(0);
       }
      }
   
    @keyframes moveInBotton {
   
       0%{
           opacity: 0;
           transform: translateY(3rem);
          }
      
          100%{
           opacity: 1;
           transform: translate(0);
          }
      }


    @keyframes typewriter-bio-main {
        from{width: 0; }
        to{width: 21em;}
    }

    @keyframes typewriter-bio-main-res {
        from{width: 0; }
        to{width: 20em;}
    }


    @keyframes blinkTextCursor-bio-main {
        from{border-right-color: rgba(255,255,255,.75);}
        to{border-right-color: transparent;}
    }


    @keyframes typewriter-bio-sub {
        from{width: 0; }
        to{width: 43em;}
        }

        
    @keyframes typewriter-bio-sub-res {
        from{width: 0; }
        to{width: 34em;}
        }

    @keyframes blinkTextCursor-bio-sub {
        from{border-right-color: rgba(255,255,255,.75);}
        to{border-right-color: transparent;}
        }