.footer{
    color: $color-grey-light-1;
    background-color: $color-grey-dark-3;
    padding:10rem 0;
    font-size: 1.4rem;

    @include respond (tab-port) {
        padding: 8rem 0;
    }

    &__logo-box{
    text-align: center;
    margin-bottom: 8rem;

    @include respond (tab-port) {
        margin-bottom: 6rem;
    }
    }

    &__logo-box{
    text-align: center;
    margin-bottom: 8rem;
    }

    &__logo{
    width: 15rem;
    height: auto;
    }

    &__navigation {
    border-top: 1px solid $color-grey-light-2;
    padding:2rem;
    float: right;

    @include respond (tab-port) {
        width: 100%;
        padding:1rem;
        text-align: center;
    }
    };

    &__list {
      list-style: none;
      text-align: center;
      
    };

    &__item {
     display: inline-block;
     &:not(:last-child) {
         margin-right: 1.5rem;
     }
    };

    &__link {
        &:link,
        &:visited {
            color: $color-grey-light-1;
            text-decoration: none;
            text-transform: uppercase;
            display:inline-block;
        }

        &:hover,
        &:active {
            background-color: $color-grey-dark;
            transition: all .2s;
            color: $color-pink-medium;
            box-shadow: 0 1rem 3rem rgba($color-black, .20);
            transform: rotate(5deg) scale(1.3);
        }

    };
    
    &__copyright {
        border-top: 1px solid $color-grey-light-2;
        padding:2rem;
        width:80%;
        float:right;
    }
}