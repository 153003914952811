
.section-stories {
    margin-top: -15.5rem;
    padding: 15rem 0rem ; 
    background-image: linear-gradient(to right bottom, $color-grey-light-1 ,$color-grey-light-2);
    @include respond (tab-port) {
        padding:10rem 0;
        margin-top:-25.5rem;
    }
}
// this is the part we using for center the about me card
.about-card-container {

    display: flex;
    justify-content: center;
    padding: 0;

    @include respond (tab-port) {
        padding:10rem 0;
        margin-top: 7.5rem;
    }
}

.section-portfolio {
    background-color: $color-grey-light-1;
    padding:10rem 0 20rem 0;
    margin-top: -15rem;

    @include respond (tab-port) {
        padding:5rem 0 15rem 0;
    }
}

.section-contact{

    padding:15rem 10rem ;
    background-image: linear-gradient(to right bottom, $color-pink-light-1, $color-pink-medium);
    @include respond (phone) {
        padding: 10rem 0 ;
     
    }
}

.contact {
    background-image:linear-gradient(105deg, rgba($color-white, .8) 0%,
    rgba($color-white, .8) 50%,
     transparent 50%) ,
      url(../../assets/images/back6.jpg);
    background-size: cover;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .20);
    width: 100%;
    margin-top: -9rem;
    animation: moveInRight 1s ease-out;

    
    @include respond (tab-land) {
      
        background-image:linear-gradient(105deg, rgba($color-white, .8) 0%,
        rgba($color-white, .8) 63%,
         transparent 63%) ,
          url(../../assets/images/back6.jpg);
          background-size: cover;
    }

    @include respond (tab-port) {
        background-image:linear-gradient(to right, 
        rgba($color-white, .8) 0%,
        rgba($color-white, .8) 100%) ,
          url(../../assets/images/back6.jpg);

          margin-top: 2rem;
    }


    &__form {

        width: 50%;
        padding: 6rem;

        
        @include respond (tab-land) {
      
            width: 63%
            }
    
            
        @include respond (tab-port) {
        
            width: 100%;
            
        }
    }
}


.skill-section {
    margin-top: 7rem;
    padding: 0 2rem;
}

.skill-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}