
.row {
    max-width: $grid-width;
    margin: 0 auto;
    
    &:not(:last-child){

        margin-bottom: $gutter-vertical;

        @include respond(tab-port) {
            margin-bottom:  $gutter-vertical-small;
         }
    };

    @include respond(tab-port) {
        max-width: 50rem; 
        padding: 0 3rem;
     }

    @include clearfix;

    [class^="col-"] {

        &:not(:last-child){

            margin-right: $gutter-horizontal;
            
            @include respond(tab-port) {
                margin-right: 0;
                margin-bottom:  $gutter-vertical-small;
             }
        };
        float: left;

        @include respond(tab-port) {
            width: 100% !important;
        }
    }

    .col-1-of-2{
        width: calc((100% - #{$gutter-horizontal}) /2);
}

    .col-1-of-3{
        width: calc((100% - 2*#{$gutter-horizontal}) /3);
    }

    .col-2-of-3{
        width: calc(2*((100% - 2*#{$gutter-horizontal}) /3) + #{$gutter-horizontal});
    }

    .col-1-of-4{
        width: calc((100% - 3*#{$gutter-horizontal}) /4);
    }


}