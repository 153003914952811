.navigation {
    &__checkbox{
     
     display: none;
    };

    &__button {
    background-color: $color-white;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 6rem;
    right:6rem;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 3rem 5rem rgba($color-black, .20);
    text-align: center;
    cursor: pointer;

    @include respond (tab-port) {

        top: 4rem;
        right:6rem;
        };
    
        @include respond (phone) {
            
            top: 2rem;
            right: 4.5rem;
        }

    };

    &__background {
    height: 6rem;
    width:6rem;
    border-radius: 50%;
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient($color-pink-light-1, $color-pink-medium);
    z-index: 1000;
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
    
    @include respond (tab-port) {
        
        top: 4.5rem;
        right:6.5rem;
        }
   
    
  
    @include respond (phone) {
        
        top: 2.5rem;
        right: 4.5rem;
        }
    
    };
    &__nav {
        height: 100vh;
        width: 0;
        position: fixed;
        top:0;
        right:0;
        z-index: 1500;
        opacity: 0;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        
        
    };
    &__list {
        position: absolute;
        width: 100%;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        text-align: center;
        list-style: none;

        @include respond (phone) {
            
            top: 39%;
            left: 44%;
        }
    }
    &__item {
      margin: 1rem;
    };
    &__link {
        &:link,
        &:visited {
            display: inline-block;
            font-size: 3rem;
            font-weight: 300;
            padding: 1rem 2rem;
            color:$color-white;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50% );
            background-size: 220%;
            transition: all  0.4s;
        };

        &:hover,
        &:active  {
            
            background-position: 100%;
            color: $color-pink-dark;
            transform: translateX(1rem);
        }

    }

    &__checkbox:checked ~ &__background {
      transform: scale(60);
    };
    
    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
      };

      &__icon {
          position: relative;
          margin-top: 3.5rem;
          &,
          &::after,
          &::before {
              width: 3rem;
              height: 2px;
              background-color: $color-grey-dark;
              display: inline-block;
          }
          &::after,
          &::before {
            content:"";
            position: absolute;
            left:0;
            transition: all .2s;
          }
          &::before {
              top: -.8rem;
          }
          &::after {
            top: .8rem;
        }
      };

      &__button:hover &__icon::before {
          top:-1rem;
      };
      &__button:hover &__icon::after {
        top:1rem;
    };

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }
    &__checkbox:checked + &__button &__icon::before {
        top:0;
        transform: rotate(135deg);
    }
    &__checkbox:checked + &__button &__icon::after {
        top:0;
        transform: rotate(-135deg);
    }
}