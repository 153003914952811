*::before {
    margin:0;
    padding:0;
    box-sizing: inherit;
}

html{
    //define what 1rem = 10px, 10/16 = 62.5%
font-size: 62.5%;
scroll-behavior: smooth;

@include respond(tab-land) { //<900px
    font-size: 56.25%;  //1rem 9px, 9/16 = 56.25%
    scroll-behavior: smooth;
}

@include respond(tab-port) { //<600px
    font-size: 50%; //1rem 8px, 8/16 = 50%
    scroll-behavior: smooth;
}


@include respond(big-desktop) {
    font-size: 75%;  //1rem 12px, 12/16 = 75%
    scroll-behavior: smooth;
}

}


body {

    box-sizing:border-box;
    padding: 3rem;

    @include respond(phone) { //<600px
        padding: 0; //1rem 8px, 8/16 = 50%
    }
    
}