body {
    font-family: 'Noto Sans HK', sans-serif;
    font-weight: 400;
    font-size:1.3rem;
    line-height: 1.7;
    color : $color-pink-dark;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;
    backface-visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
 
    &-main {

        display: block;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing:1rem;
        border-right: 2px solid rgba(255,255,255,.75);
        overflow: hidden; 
        white-space: nowrap;
        margin-bottom: 2rem;
        animation: typewriter-bio-main 4s steps(44) 1s 1 normal both,
                   blinkTextCursor-bio-main 500ms steps(44) infinite normal;
         

        @include respond(tab-land) { 
            letter-spacing:1rem; 
            font-size: 3rem;

        }

        @include respond(phone) { 
            letter-spacing: .5rem; 
            font-size: 2rem;
          

            animation: typewriter-bio-main-res 4s steps(44) 1s 1 normal both,
            blinkTextCursor-bio-main 500ms steps(44) infinite normal;

        }

        

    }
    
    &-sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        width: 60rem;
        letter-spacing: 1.3rem;
        border-right: 2px solid rgba(255,255,255,.75);
        white-space: pre;
        overflow: hidden; 
        margin-bottom: 4.5rem;
        animation: typewriter-bio-sub 4s steps(44) 1s 1 normal both,
                   blinkTextCursor-bio-sub 500ms steps(44) infinite normal;
        @include respond(phone) { 
            letter-spacing: 0.5rem; 
            font-size: 1.3rem;

            animation: typewriter-bio-sub-res 4s steps(44) 1s 1 normal both,
            blinkTextCursor-bio-sub 500ms steps(44) infinite normal;
    
        }
    }
}

.text-box {
    position: absolute;
    width: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;

    @include respond(phone) { 
        width: auto;

    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image:linear-gradient(to right bottom, $color-pink-light-1, $color-pink-medium);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;

    @include respond(tab-port) {  
        font-size:3rem;
    }

    @include respond(phone) {  
        font-size:2.5rem;
    }


    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
      
    }
}

.heading-third {
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    background-image:linear-gradient(to right bottom, $color-pink-light-1, $color-pink-medium);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;

    @include respond(tab-port) {  
        font-size:2.5rem;
    }

    @include respond(phone) {  
        font-size:1.5rem;
    }


    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
      
    }
}
  

.heading-typo {
    position: relative;
    color: $color-white;
    text-transform: uppercase;
    backface-visibility: hidden;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);   
    
    @include respond(tab-port) {  
        width: 12em;
        font-size: 3rem;
       
    }

    @include respond(phone) {  
        width: 12em;
        font-size: 3rem;

    }
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;


}
@keyframes typewriter{
    from{width: 0;}
    to{width: 14em;}
  }
  @keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }


  .heading-about {

    text-transform: uppercase;
    display: inline-block;
    background-image:linear-gradient(to right bottom, $color-pink-light-1, $color-pink-medium);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    &__main {

        font-size: 3.5rem;
        font-weight: 700;
        letter-spacing: .2rem;
    
        @include respond(tab-port) {  
            font-size:3rem;
        }
    
        @include respond(phone) {  
            font-size:2.5rem;
        }
    
    
        &:hover {
            text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
        }
    }

    &__sub {

        font-size: 2rem;
        font-weight: 700;
        letter-spacing: .2rem;

        @include respond(tab-port) {  
           
            font-size:2.5rem;
        }
       
    
        @include respond(phone) {  
           
            font-size:1.5rem;
        }
    
    
        &:hover {
            text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;  
        }
    }

  }


  .skill-typo {
      
    font-family: 'Noto Sans HK', sans-serif;
    font-weight: 400;
    font-size:1.5rem;
    line-height: 1.7;
    text-align: center;
    color : $color-pink-dark;
  }



  