//colors 

$color-pink-light-0: rgb(241, 183, 208);
$color-pink-light-1: #E99ABB;
$color-pink-light : #D96C9A;
$color-pink-root: #AC3064;
$color-pink-medium: #841041;
$color-pink-dark: #4F0021;


$color-white : #fff;
$color-black: #000;
$color-grey-dark : #777;
$color-grey-dark-2:#333;
$color-grey-dark-3:rgb(94, 94, 94);
$color-alert: rgb(161, 10, 30);

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

//grid:

$grid-width: 114rem;
$gutter-vertical : 8rem;
$gutter-vertical-small : 6rem;
$gutter-horizontal: 6rem;

//font size:

$default-font-size: 2rem